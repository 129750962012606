<style lang="scss">
#login-client {
  .text-info {
    cursor: pointer;
  }
}
</style>
<template>
  <div id="login-client">
    <div class="container">
      <div id="login-row" class="row justify-content-center align-items-center">
        <div id="login-column card" class="col-md-6 card mt-40">
          <div id="login-box" class="col-md-12 card-body">
            <form id="login-form" class="form" @submit.stop.prevent="onSubmit">
              <h3 class="text-center text-info">
                <img
                  :src="
                    getServiceProviderData.image == null
                      ? getServiceProviderData.logo
                      : getServiceProviderData.image
                  "
                  style="width:25%"
                  alt="logo"
                />
              </h3>
              <h4 class="text-center mt-5">
                Change password for <br />
                @{{ getServiceProviderData.username }}
              </h4>
              <hr />
              <div
                class="alert alert-danger"
                role="alert"
                v-if="errorMsg != ''"
              >
                {{ errorMsg }}
              </div>
              <div class="form-group" style="position: relative;">
                <label for="email" class="text-dark text-uppercase"
                  >Password</label
                >
                <input
                  :type="password_type"
                  v-model="password"
                  class="form-control"
                  :class="errors['password'] ? 'border border-danger' : ''"
                />
                <a @click="showPassword()" style="cursor: pointer;">
                  <i
                    :class="
                      showPasswordIcon
                        ? 'fa fa-eye text-info'
                        : 'fa fa-eye-slash'
                    "
                    style="position: absolute;right: 10px;top: 36px;"
                  ></i
                ></a>
                <span v-if="errors['password']" class="text-danger">
                  {{ errors["password"][0] }}
                </span>
              </div>

              <div class="form-group">
                <label for="email" class="text-dark text-uppercase"
                  >Confirm password</label
                >
                <input
                  type="password"
                  v-model="confirm_password"
                  class="form-control"
                  @input="checkConfirmPassowrd()"
                  :class="
                    errors['confirm_password'] ? 'border border-danger' : ''
                  "
                />
                <span v-if="errors['confirm_password']" class="text-danger">
                  {{ errors["confirm_password"][0] }}
                </span>
              </div>

              <!-- <p>
                Make sure it's at least 15 characters OR at least 8 characters
                including a number and a lowercase letter.
              </p> -->

              <transition name="hint" appear>
                <div v-if="passwordValidation.errors.length > 0" class="hints">
                  <h5>Hints</h5>
                  <hr />
                  <ul style="padding: 0px;padding-left: 15px;">
                    <li
                      v-for="(error, index) in passwordValidation.errors"
                      :key="index"
                      class="text-danger"
                    >
                      <i class="fa fa-dot"></i> {{ error }}
                    </li>
                  </ul>
                </div>
              </transition>

              <button
                style="width:100%"
                ref="kt_password_reset"
                class="btn btn-info btn-md mt-0"
              >
                Change Password
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  FETCH_SERVICE_PROVIDER_DATA,
  CHANGE_USER_PASSWORD
} from "@/core/services/store/actions.type";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      showPasswordIcon: false,
      password_type: "password",
      // Remove this dummy login info
      errorMsg: "",
      password: "",
      confirm_password: "",
      client_slug: "",
      errors: [],
      rules: [
        { message: "One lowercase letter required.", regex: /[a-z]+/ },
        { message: "One uppercase letter required.", regex: /[A-Z]+/ },
        { message: "8 characters minimum.", regex: /.{8,}/ },
        { message: "One number required.", regex: /[0-9]+/ },
        { message: "One special character required.", regex: /[!@#$%^&*]+/ }
      ]
    };
  },
  created() {
    this.client_slug = this.$route.params.client_slug;
    this.$store.dispatch(FETCH_SERVICE_PROVIDER_DATA, {
      client_slug: this.client_slug,
      fetch_slug: "pr",
      reset_token: this.$route.params.reset_token
    });
  },
  computed: {
    ...mapGetters(["getServiceProviderData"]),
    passwordValidation() {
      let errors = [];
      for (let condition of this.rules) {
        if (!condition.regex.test(this.password)) {
          errors.push(condition.message);
        }
      }
      if (errors.length === 0) {
        return { valid: true, errors };
      } else {
        return { valid: false, errors };
      }
    }
  },
  methods: {
    showPassword() {
      if (!this.showPasswordIcon) {
        this.showPasswordIcon = true;
        this.password_type = "text";
      } else {
        this.showPasswordIcon = false;
        this.password_type = "password";
      }
    },
    checkConfirmPassowrd() {
      this.errors = [];
      if (this.password != this.confirm_password) {
        this.errors.confirm_password = {
          0: "Password does not matched"
        };
      }
    },

    onSubmit() {
      var val = this.passwordValidation.valid;
      if (val) {
        this.errors = [];
        if (this.password == "" || this.confirm_password == "") {
          if (this.password == "") {
            this.errors["password"] = {
              0: "This field is required"
            };
          }
          if (this.confirm_password == "") {
            this.errors.confirm_password = {
              0: "This field is required"
            };
          }
        } else if (this.password != this.confirm_password) {
          this.errors.confirm_password = "Password does not matched";
        } else {
          // s spinner to submit button
          const submitButton = this.$refs["kt_password_reset"];
          submitButton.disabled = true;
          submitButton.classList.add(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          this.$store
            .dispatch(CHANGE_USER_PASSWORD, {
              reset_token: this.$route.params.reset_token,
              client_slug: this.client_slug,
              password: this.password
            })
            .then(data => {
              submitButton.classList.remove(
                "spinner",
                "spinner-light",
                "spinner-right"
              );
              submitButton.disabled = false;

              this.$toastr.s(data.msg);
              this.$router.push({
                name: "client.login",
                query: { s: this.client_slug }
              });
            })
            .catch(err => {
              this.errors = err;
              submitButton.classList.remove(
                "spinner",
                "spinner-light",
                "spinner-right"
              );
              submitButton.disabled = false;
            });
        }
      }
    }
  }
};
</script>
